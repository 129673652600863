<template>
  <main class="additionComponentPg">
    <h6 class="mb-0 p-2 primaryBg whiteText webHead">Add Misc. Items</h6>
    <div
      class="mobileTableHead primaryBg d-flex align-items-center justify-content-between p-2 d-none"
    >
      <h6 class="mb-0 primaryBg whiteText">Add Misc. Items</h6>
      <a
        @click="addButtonClicked()"
        class="addButton d-flex align-items-center"
      >
        <span class="me-2 whiteText">Add</span>
        <img src="../../assets/images/add-green-circle.png" class="ms-1"
      /></a>
    </div>
    <div class="tableHead">
      <ul class="list-inline mb-0 d-flex border-bottom-none">
        <li class="fw-bold">Title</li>
        <li class="fw-bold">Price</li>
        <li></li>
        <li class="fw-bold text-end">
          <a
            @click="addButtonClicked()"
            class="addButton d-flex align-items-center"
          >
            <span class="me-2">Add</span>
            <img src="../../assets/images/add-green-circle.png" class="ms-1"
          /></a>
        </li>
      </ul>
    </div>

    <div class="tableBody">
      <ul
        class="list-inline mb-0 d-flex align-items-center"
        v-for="(item, index) in additionalItems"
        :key="index"
      >
        <li>
          <div class="form-group">
            <label for="Title" class="mobileLabel">Title</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Title"
              v-model="item.title"
            />
          </div>
        </li>
        <li>
          <div class="form-group">
            <label for="Title" class="mobileLabel">Price</label>
            <input
              type="tel"
              class="form-control"
              placeholder="Enter Price"
              v-model="item.price"
              @paste="disablePaste($event)"
              @keypress="isNumberLimitation($event)"
              @input="isNumberLimitation($event)"
              @drop.prevent
              @dragover.prevent
            />
          </div>
        </li>
        <li>
          <div class="form-group">
            <div class="radioCheck customRadiocheck d-flex align-items-center">
              <div class="form__radio-group d-flex align-items-center">
                <input
                  :id="index + '-Yes'"
                  type="radio"
                  class="form__radio-input"
                  :name="index"
                  v-model="item.type"
                  value="1"
                />
                <label
                  :for="index + '-Yes'"
                  class="form__radio-label yellowRadio"
                >
                  <span class="form__radio-button"></span>
                </label>
                <h6 class="mb-0 me-3">Recommended</h6>
              </div>
              <div class="form__radio-group d-flex align-items-center">
                <input
                  :id="index + '-No'"
                  type="radio"
                  class="form__radio-input"
                  v-model="item.type"
                  value="2"
                  :name="index"
                />
                <label :for="index + '-No'" class="form__radio-label redRadio">
                  <span class="form__radio-button"></span>
                </label>
                <h6 class="mb-0">Required</h6>
              </div>
            </div>
          </div>
        </li>
        <li class="text-end">
          <a @click="removeItem(index)" class="removeRow"
            ><img src="../../assets/images/trash-icon.png" alt=""
          /></a>
        </li>
      </ul>
    </div>
  </main>
</template>

<script>
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {},
  props: ["additionalItems"],
  data() {
    return {
      // items: this.additionalItems
    };
  },

  methods: {
    formatValue(event) {
      let inputValue = event.target.value;
      // Replace any non-digit characters except for a single dot
      inputValue = inputValue.replace(/[^0-9.]/g, "");
      // Limit to 2 decimal places
      const parts = inputValue.split(".");
      if (parts[1]) {
        parts[1] = parts[1].slice(0, 2);
      }
      this.value = parts.join(".");
    },
    addButtonClicked() {
      let res = true;
      let msg = "";
      this.additionalItems.forEach((element) => {
        if (element.title.length <= 0) {
          msg = "Title field is required.";
          res = false;
        } else if (element.price.length <= 0) {
          msg = "Price field is required.";
          res = false;
        }
      });

      if (res) {
        this.additionalItems.unshift({ type: 1, price: "", title: "" });
      } else {
        toast.error(msg);
      }
    },

    removeItem(index) {
      this.additionalItems.splice(index, 1);
    },

    disablePaste(event) {
      event.preventDefault();
    },

    isNumberLimitation(evt) {
  const inputElement = evt.target;
  let value = inputElement.value;

  if (evt.type === 'input') {
    // Remove non-numeric and multiple decimal points
    value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');

    const [integer, decimal] = value.split('.');

    // Restrict integer part to 5 digits
    if (integer.length > 5) value = integer.slice(0, 5);

    // Restrict decimal part to 2 digits
    if (decimal) {
      if (integer === "") {
        value = "";
      } else {
        value = `${integer}.${decimal.slice(0, 2)}`;
      }
    }

    // Prevent starting with '.'
    if (value.startsWith('.') || value === '') {
      value = '';
    }

    inputElement.value = value;
    inputElement.dispatchEvent(new Event('input', { bubbles: true }));

  } else if (evt.type === 'keypress') {
    const charCode = evt.which || evt.keyCode;

    // Allow backspace, delete, tab, numbers (0-9), and a single '.'
    if (charCode === 8 || charCode === 46 || charCode === 9 || (charCode >= 48 && charCode <= 57)) {
      if (charCode === 46 && value.indexOf('.') !== -1) {
        evt.preventDefault();
        return false;
      }
      return true;
    }
    evt.preventDefault();
    return false;
  }
},

    isNumber(evt) {
      const inputElement = evt.target;
      let value = inputElement.value;
      if (evt.type === 'input') {
          value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
          const [integer, decimal] = value.split('.');
          if (integer.length > 4) value = integer.slice(0, 4);
          if (decimal) {
            if (integer === "") {
              value = "";
            } else {
              value = `${integer}.${decimal.slice(0, 2)}`;
            }
          }
          if (value.startsWith('.') || value === '') {
            value = '';
          }
          inputElement.value = value;
          inputElement.dispatchEvent(new Event('input', { bubbles: true }));
        } else if (evt.type === 'keypress') {
        const charCode = evt.which || evt.keyCode;
        if (charCode === 8 || charCode === 46 || charCode === 9 || (charCode >= 48 && charCode <= 57) ) {
          if (charCode === 46 && value.indexOf('.') !== -1) {
            evt.preventDefault();
            return false;
          }
          return true;
        }
        evt.preventDefault();
        return false;
      }
      // Remove invalid characters and multiple decimal points
      value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      const [integer, decimal] = value.split('.');
      // Restrict length
      if (integer.length > 4) value = integer.slice(0, 4);
      if (decimal) value = value.split('.')[0] + '.' + decimal.slice(0, 2);
      inputElement.value = value;
    },
  },
};
</script>

<style></style>
