// import { GET_ALL_PRODUCTS } from "./Types";
import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();
import { globals } from "../../main";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { faL } from "@fortawesome/free-solid-svg-icons";

// initial state
const state = () => ({
  user: {},
  isSilentLogin: true,
  apiCallback: true,

  //View Appointment
  getAppointmentDetails: true,
  apptDetail: {},

  // Mark as enroute and Arrived actions
  appointmentAction: true,
  saveApptointmentActionEnroute: {},
  saveApptointmentActionArrived: {},

  //Start Quote
  saveApptointmentCreatelObject: {},

  //Company Info
  setCompanyInfo: true,
  saveCompanyInfo: {},

  //Get Equipment Info
  setEquipmentInfo: true,
  saveEquipmentInfo: {},
  saveHvacEquipmentInfo: {},
  saveHvacConfig: {},

  //Store Equipment Info
  saveEquipmentInfoStore: {},
  saveHvacEquipmentInfoStore: {},
  setEquipmentInfoStore: true,

  //Notes Info
  notesData: {},

  //Part Categories
  partCategories: [],

  //Part List
  partList: [],

  // Store Quote Items
  storeQuoteItems: {},

  // Store Quote Details
  quoteDetail: {},

  cancelReasons: [],

  //CancelAppoiuntment Response
  cancelAppointment: {},

  quoteSummary: {},

  updateQuoteSummary: {},

  componentState: "ClaimDetails",

  qouteNotes: [],

  checkoutResponse: {},
  qrResponse: {},

  quoteEmailResponse: {},
  quoteImageUploaded: {},
  bookingImageUploaded: {},

  quoteImages: [],
  deleteQuoteImage: false,

  dashboardData: {},
  QRData: {},
  inProgressJobs: [],
  completedJobs: [],
  jobDetail: {},
  bookingDiagnosisItems: {},
  diagnosisSubmitResponse: {},
  diagnosisUpdateResponse: {},
  postUnitResponse: {},
  bindUnitResponse: {},
  collectDataResponse: {},
  updateCollectDataResponse: {},
  updateJobStatusResponse: {},
  resumeJobResponse: {},
  endJobResponse: {},
  resubmitQuoteResponse: {},
  bookingResponse: {},
  storeChecksResponse: {},
  bookingNotes: [],
  markAsCompleteObject: {},
  partsDetails: [],
  unitsList: [],
  initialChecksList: {},
  consumerMembers: [],
  equipmentChecksList: {},
  services: {},
  bookingServices: {},
  bookingParts: [],
  bookingDiagnosisOptions: [],
  getDiagnosisOptions: {},
  allDiagnosisOptions: {},
  markAsCompleteResponse: {},
  preChecksDetail: [],
  specialParts: [],
  summaryDetail: {},
  preChecksStore: {},
  preConfig: {},
  config: {},
  bootMeUpData: {},
  bookingConverted: {},
  bookingDocs: [],
  removeBookingDocs: {},
  deleteNoteResponse: {},
  addNoteResponse: {},
  componentList : [],
  waiveTradeCallFee : false,
  resubmitQuote : false

});

// getters
const getters = {
  user: (state) => state.user,
  isSilentLogin: (state) => state.isSilentLogin,
  isSSOLogin: (state) => state.isSSOLogin,
  apiCallback: (state) => state.apiCallback,

  //View Appointment
  getAppointmentDetails: (state) => state.getAppointmentDetails,
  apptDetail: (state) => state.apptDetail,

  //Mark as enroute and Arrived actions
  appointmentAction: (state) => state.appointmentAction,
  saveApptointmentActionEnroute: (state) => state.saveApptointmentActionEnroute,
  saveApptointmentActionArrived: (state) => state.saveApptointmentActionArrived,

  //Start Quote
  saveApptointmentCreatelObject: (state) => state.saveApptointmentCreatelObject,

  //Company Info
  setCompanyInfo: (state) => state.setCompanyInfo,
  saveCompanyInfo: (state) => state.saveCompanyInfo,

  //Get Equipment Info
  setEquipmentInfo: (state) => state.setEquipmentInfo,
  saveEquipmentInfo: (state) => state.saveEquipmentInfo,
  saveHvacEquipmentInfo: (state) => state.saveHvacEquipmentInfo,
  saveHvacConfig: (state) => state.saveHvacConfig,

  //Store Equipment Info
  saveEquipmentInfoStore: (state) => state.saveEquipmentInfoStore,
  saveHvacEquipmentInfoStore: (state) => state.saveHvacEquipmentInfoStore,
  setEquipmentInfoStore: (state) => state.setEquipmentInfoStore,

  //Notes Info
  notesData: (state) => state.notesData,

  //Part Categories
  partCategories: (state) => state.partCategories,

  partList: (state) => state.partList,

  storeQuoteItems: (state) => state.storeQuoteItems,

  quoteDetail: (state) => state.quoteDetail,

  cancelReasons: (state) => state.cancelReasons,

  quoteSummary: (state) => state.quoteSummary,

  updateQuoteSummary: (state) => state.updateQuoteSummary,

  componentState: (state) => state.componentState,

  qouteNotes: (state) => state.qouteNotes,

  cancelAppointment: (state) => state.cancelAppointment,

  checkoutResponse: (state) => state.checkoutResponse,

  qrResponse: (state) => state.qrResponse,

  quoteEmailResponse: (state) => state.quoteEmailResponse,
  quoteImageUploaded: (state) => state.quoteImageUploaded,
  bookingImageUploaded: (state) => state.bookingImageUploaded,

  quoteImages: (state) => state.quoteImages,

  deleteQuoteImage: (state) => state.deleteQuoteImage,

  dashboardData: (state) => state.dashboardData,
  QRData: (state) => state.QRData,
  inProgressJobs: (state) => state.inProgressJobs,
  completedJobs: (state) => state.completedJobs,
  jobDetail: (state) => state.jobDetail,
  bookingDiagnosisItems: (state) => state.bookingDiagnosisItems,
  diagnosisSubmitResponse: (state) => state.diagnosisSubmitResponse,
  diagnosisUpdateResponse: (state) => state.diagnosisUpdateResponse,
  postUnitResponse: (state) => state.postUnitResponse,
  bindUnitResponse: (state) => state.bindUnitResponse,
  collectDataResponse: (state) => state.collectDataResponse,
  updateCollectDataResponse: (state) => state.updateCollectDataResponse,
  updateJobStatusResponse: (state) => state.updateJobStatusResponse,
  resumeJobResponse: (state) => state.resumeJobResponse,
  endJobResponse: (state) => state.endJobResponse,
  resubmitQuoteResponse: (state) => state.resubmitQuoteResponse,
  bookingResponse: (state) => state.bookingResponse,
  storeChecksResponse: (state) => state.storeChecksResponse,
  bookingNotes: (state) => state.bookingNotes,
  markAsCompleteObject: (state) => state.markAsCompleteObject,
  partsDetails: (state) => state.partsDetails,
  unitsList: (state) => state.unitsList,
  initialChecksList: (state) => state.initialChecksList,
  consumerMembers: (state) => state.consumerMembers,
  equipmentChecksList: (state) => state.equipmentChecksList,
  bookingServices: (state) => state.bookingServices,
  bookingParts: (state) => state.bookingParts,
  bookingDiagnosisOptions: (state) => state.bookingDiagnosisOptions,
  getDiagnosisOptions: (state) => state.getDiagnosisOptions,
  allDiagnosisOptions: (state) => state.allDiagnosisOptions,
  markAsCompleteResponse: (state) => state.markAsCompleteResponse,
  preChecksDetail: (state) => state.preChecksDetail,
  specialParts: (state) => state.specialParts,
  summaryDetail: (state) => state.summaryDetail,
  preChecksStore: (state) => state.preChecksStore,
  preConfig: (state) => state.preConfig,
  config: (state) => state.config,
  bootMeUpData: (state) => state.bootMeUpData,
  services: (state) => state.services,
  bookingConverted: (state) => state.bookingConverted,
  bookingDocs: (state) => state.bookingDocs,
  removeBookingDocs: (state) => state.removeBookingDocs,
  deleteNoteResponse: (state) => state.deleteNoteResponse,
  addNoteResponse: (state) => state.addNoteResponse,
  componentList : (state) => state.componentList,
  waiveTradeCallFee : (state) => state.waiveTradeCallFee,
  resubmitQuote : (state) => state.resubmitQuote
};

// actions
const actions = {

  async resubmitManualQuote({ commit }, params) {
    startLoader("");
    await axios
      .post("manual-quote/partner/resubmit-quote", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveresubmitManualQuote", true);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
      });
  },

  async waiveTradeCall({ commit }, params) {
    startLoader("");
    await axios
      .post("manual-quote/partner/trade-call-fee/waive", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveWaiveTradeCallFee", true);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
      });
  },

  async getComponentList({ commit }, params) {
    startLoader("");
    await axios
      .get("parts/component-list", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveComponentList", res.data.body);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
      });
  },




  async bookingDocumentDelete({ commit }, params) {
    startLoader("");
    await axios
      .delete("bookings/document/other/remove", { params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("removeBookingDocument", res.data.body);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
      });
  },

  async deleteBookingNote({ commit }, params) {
    startLoader("");
    await axios
      .post("bookings/notes/delete/" + params.booking_id, params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveDeleteNotes", res.data.body);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
      });
  },

  async addBookingNote({ commit }, params) {
    startLoader("");
    await axios
      .post("bookings/notes/store/" + params.booking_id, params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveAddNotes", res.data.body);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
      });
  },

  async uploadBookingImage({ commit }, params) {
    startLoader("");

    await axios
      .post("bookings/document/other/upload", params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        hideLoader("");

        if (res.data.status == true) {
          commit("saveBookingImage", res.data.body);
        } else {
          commit("saveBookingImage", res.data);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  },

  async getBookingDocs({ commit }, params) {
    startLoader("");
    await axios
      .get("bookings/document/other/list", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveBookingDocs", res.data.body);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
      });
  },

  async convretToBooking({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("appointments/convert/booking", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("bookingConvert", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getServices({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("services", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveServices", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getBootMeData({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("boot", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveBootmeUpData", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getUnitList({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("units-new/list", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveUnitsInfo", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getBookingParts({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("parts/list", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveBookingParts", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getBookingDiagnosisOptions({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("bookings/diagnosis/options", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveBookingDiagnosisOptions", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getBookingServices({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("bookings/diagnosis/revamp/services", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveBookingServices", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getEquipmentChecks({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("bookings/diagnosis/revamp/options", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveEquipmentChecks", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getConsumerMembers({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("consumer/member/list", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          if (res.data.body.length <= 0) {
            toast.error("no members found");
          }
          commit("saveConsumerMembers", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getInitialChecks({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("bookings/diagnosis/revamp/initial-checks", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveInitialChecks", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getDiagnosisOption({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("bookings/diagnosis/draft/get", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveDiagnosisOptions", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getAllDiagnosisOption({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("bookings/diagnosis/draft/all", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveAllDiagnosisOptions", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getSummaryDetail({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("bookings/diagnosis/summary/calculate", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveSummaryDetail", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getPreChecksDetails({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("bookings/diagnosis/submit/pre-checks/detail", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("savePreChecksDetails", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getSpecialParts({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("/bookings/diagnosis/special-parts", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveSpecialParts", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async storePreChecksDetails({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("bookings/diagnosis/submit/pre-checks/store", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("savePreChecksResponse", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getPreConfig({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("units-new/pre-config", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("savePreConfig", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getConfig({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("units-new/config", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveConfig", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getpartsDetails({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("bookings/diagnosis/summary/parts", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("savepartsDetails", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getBookingNotes({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("bookings/notes", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveBookingNotes", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getMarkAsCompleteObject({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("bookings/mark-as-completed/data", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveMarkAsCompleteObject", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async diagnosisSubmit({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("bookings/diagnosis/submit", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveDiagnosisSubmit", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async diagnosisUpdate({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("bookings/diagnosis/update", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveDiagnosisUpdate", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getBookingDetail({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("bookings/show", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveJobDetails", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getBookingDiagnosisItems({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("bookings/diagnosis/items", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveBookingDiagnosisItems", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getBookingList({ commit }, params) {
    let status = params["status"];
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("bookings/list", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          if (status == 8) {
            commit("saveInProgressJobs", res.data.body);
          } else {
            commit("saveCompletedJobs", res.data.body);
          }
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getQRData({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .get("qrCode/job-detail", { params: params })
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveQRData", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getDashboardData({ commit }) {
    startLoader("");

    let obj = {};
    obj["is_tech_web"] = "1";
    commit("apiCallback", true);
    await axios
      .post("side-menu", obj)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveDashboardData", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async postUnitDetails({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("units-new/update", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("postUnitDetailResponse", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async bindUnit({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("units/bind", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveBindUnit", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async postCollectData({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("bookings/diagnosis/submit/collect-data", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveCollectData", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async updateCollectData({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("bookings/diagnosis/update/collect-data", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveUpdateCollectData", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async updateJobStatus({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("bookings/status", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveUpdateJobStatus", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async resumeJob({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("bookings/status/resume", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveResumeJob", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async endJob({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("bookings/status/complete", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveEndJob", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async resubmitQuote({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("bookings/diagnosis/decline/re-submit", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveResubmitQuote", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getBookingDetails({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("bookings/show-web", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveBookingResponse", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async storeChecks({ commit }, params) {
    startLoader("");
    commit("apiCallback", true);
    await axios
      .post("bookings/diagnosis/draft/store", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("storeChecksResponse", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async proLogin({ commit }, params) {
    startLoader("");
    commit("setSilentLogin", true);
    await axios
      .post("login", params)
      .then((res) => {
        hideLoader("");
        if (res.data.status == true) {
          commit("saveUserObject", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("setSilentLogin", false);
      })
      .catch((err) => {
        hideLoader("");
        console.log("error", err);
        commit("setSilentLogin", false);
      });
  },

  async silentLogin({ commit }, params) {
    startLoader("silentLogin");
    commit("setSilentLogin", true);
    await axios
      .post("silent-login", params)
      .then((res) => {
        hideLoader("silentLogin");
        if (res.data.status == true) {
          localStorage.setItem('user', JSON.stringify(res.data.body))

          commit("saveUserObject", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("setSilentLogin", false);
      })
      .catch((err) => {
        hideLoader("silentLogin");
        console.log("error", err);
        commit("setSilentLogin", false);
      });
  },

  async setSSOLogin({ commit }, params) {
    startLoader("ssoLogin");
    commit("apiCallback", true);
    await axios
      .post("web-auth/verify", params)
      .then((res) => {
        hideLoader("ssoLogin");
        if (res.data.status == true) {
          console.log(res.data.body);
          commit("saveUserObject", res.data.body);
        } else {
          toast.error(res.data.message);
          window.location = "/sso?login-failed=true";
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("ssoLogin");
        console.log("error", err);
        commit("apiCallback", false);
      });
  },

  async getAppointmentDetailsAPICall({ commit }, params) {
    startLoader("getAppointmentDetailsAPICall");
    commit("setAppointmentDetails", true);
    await axios
      .post("/appointments/view", params)
      .then((res) => {
        hideLoader("getAppointmentDetailsAPICall");
        if (res.data.status == true) {
          commit("saveApptDetailObject", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("setAppointmentDetails", false);
      })
      .catch((err) => {
        hideLoader("getAppointmentDetailsAPICall");
        alert(err);
        commit("setAppointmentDetails", false);
      });
  },

  //pro/company/info
  async getCompanyInfo({ commit }, params) {
    startLoader("getCompanyInfo");
    commit("setCompanyInfo", true);
    await axios
      .get("/company/info", params)
      .then((res) => {
        hideLoader("getCompanyInfo");
        if (res.data.status == true) {
          commit("saveCompanyInfo", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("setCompanyInfo", false);
      })
      .catch((err) => {
        hideLoader("getCompanyInfo");
        alert(err);
        commit("setCompanyInfo", false);
      });
  },

  //pro/manual-quote/partner/equipments

  async getEquipmentInfo({ commit }, params) {
    startLoader("getEquipmentInfo");
    commit("setEquipmentInfo", true);
    await axios
      .get("/manual-quote/partner/equipments", { params: params })
      .then((res) => {
        hideLoader("getEquipmentInfo");
        if (res.data.status == true) {
          commit("saveEquipmentInfo", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("setEquipmentInfo", false);
      })
      .catch((err) => {
        hideLoader("getEquipmentInfo");
        alert(err);
        commit("setEquipmentInfo", false);
      });
  },

  //Hvac Equipment Info

  async getHvacEquipmentInfo({ commit }, params) {
    startLoader();
    commit("apiCallback", true);
    await axios
      .get("/manual-quote/partner/units/detail", { params: params })
      .then((res) => {
        hideLoader();
        if (res.data.status == true) {
          commit("saveHvacEquipmentInfo", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader();
        alert(err);
        commit("apiCallback", false);
      });
  },

  //get Hvac Config

  async getHvacConfig({ commit }, params) {
    startLoader();
    commit("apiCallback", true);
    await axios
      .get("/manual-quote/partner/units/config", { params: params })
      .then((res) => {
        hideLoader();
        if (res.data.status == true) {
          commit("saveHvacConfig", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader();
        alert(err);
        commit("apiCallback", false);
      });
  },

  //pro/manual-quote/partner/units/upsert

  async HvacEquipmentStore({ commit }, params) {
    startLoader();
    commit("apiCallback", true);
    await axios
      .post("/manual-quote/partner/units/upsert", params)
      .then((res) => {
        hideLoader();
        if (res.data.status == true) {
          commit("saveHvacEquipmentInfoStore", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader();
        alert(err);
        commit("apiCallback", false);
      });
  },

  //pro/manual-quote/partner/equipments/store

  async equipmentInfoStore({ commit }, params) {
    startLoader("equipmentInfoStore");
    commit("setEquipmentInfoStore", true);
    await axios
      .post("/manual-quote/partner/equipments/store", params)
      .then((res) => {
        hideLoader("equipmentInfoStore");
        if (res.data.status == true) {
          commit("saveEquipmentInfoStore", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("setEquipmentInfoStore", false);
      })
      .catch((err) => {
        hideLoader("equipmentInfoStore");
        alert(err);
        commit("setEquipmentInfoStore", false);
      });
  },

  //pro/appointments/en-route

  async markAsEnrouteAPICall({ commit }, params) {
    startLoader("markAsEnrouteAPICall");
    commit("setAppointmentAction", true);
    await axios
      .put("/appointments/en-route", params)
      .then((res) => {
        hideLoader("markAsEnrouteAPICall");
        if (res.data.status == true) {
          commit("saveApptointmentActionEnroute", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("setAppointmentAction", false);
      })
      .catch((err) => {
        hideLoader("markAsEnrouteAPICall");
        alert(err);
        commit("setAppointmentAction", false);
      });
  },

  //pro/appointments/arrived

  async markAsArrivedAPICall({ commit }, params) {
    startLoader("markAsArrivedAPICall");
    commit("setAppointmentAction", true);
    await axios
      .put("/appointments/arrived", params)
      .then((res) => {
        hideLoader("markAsArrivedAPICall");
        if (res.data.status == true) {
          commit("saveApptointmentActionArrived", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("setAppointmentAction", false);
      })
      .catch((err) => {
        hideLoader("markAsArrivedAPICall");
        alert(err);
        commit("setAppointmentAction", false);
      });
  },

  //pro/appointments/uq/create
  async appointmentCreate({ commit }, params) {
    startLoader("appointmentCreate");
    commit("setAppointmentAction", true);
    await axios
      .post("/appointments/uq/create", params)
      .then((res) => {
        hideLoader("appointmentCreate");
        if (res.data.status == true) {
          commit("saveApptointmentCreatelObject", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("setAppointmentAction", false);
      })
      .catch((err) => {
        hideLoader("appointmentCreate");
        alert(err);
        commit("setAppointmentAction", false);
      });
  },

  //Notes Update
  async updateNote({ commit }, params) {
    startLoader("updateNote");
    commit("apiCallback", true);
    await axios
      .put(`/manual-quote/notes/update/${params.id}`, params)
      .then((res) => {
        hideLoader("updateNote");
        if (res.data.status == true) {
          commit("notesData", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("updateNote");
        alert(err);
        commit("apiCallback", false);
      });
  },

  //Notes Add
  async addNote({ commit }, params) {
    startLoader("addNote");
    commit("apiCallback", true);
    await axios
      .post("/manual-quote/update-notes", params)
      .then((res) => {
        hideLoader("addNote");
        if (res.data.status == true) {
          commit("notesData", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("addNote");
        alert(err);
        commit("apiCallback", false);
      });
  },

  //Notes Delete
  async deleteNote({ commit }, params) {
    startLoader("deleteNote");
    commit("apiCallback", true);
    await axios
      .delete(`/manual-quote/notes/delete/${params.id}`)
      .then((res) => {
        hideLoader("deleteNote");
        if (res.data.status == true) {
          commit("notesData", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("deleteNote");
        alert(err);
        commit("apiCallback", false);
      });
  },

  //pro/partner/parts/categories
  async getPartCategories({ commit }, params) {
    startLoader("getPartCategories");
    commit("apiCallback", true);
    await axios
      .get("/partner/parts/categories", { params: params })
      .then((res) => {
        hideLoader("getPartCategories");
        if (res.data.status == true) {
          commit("savePartCategories", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("getPartCategories");
        alert(err);
        commit("apiCallback", false);
      });
  },

  //pro/partner/parts/list

  async getPartList({ commit }, params) {
    startLoader("getPartList");
    commit("apiCallback", true);
    await axios
      .get("/partner/parts/list", { params: params })
      .then((res) => {
        hideLoader("getPartList");
        if (res.data.status == true) {
          commit("savePartList", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("getPartList");
        alert(err);
        commit("apiCallback", false);
      });
  },

  // Store Quote Items
  async postQuoteData({ commit }, params) {
    startLoader("postQuoteData");
    commit("apiCallback", true);
    await axios
      .post("/manual-quote/partner/items/collect", params)
      .then((res) => {
        hideLoader("postQuoteData");
        if (res.data.status == true) {
          commit("storeQuoteItems", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader("postQuoteData");
        alert(err);
        commit("apiCallback", false);
      });
  },

  // Store Quote Detail
  async getQuoteDetail({ commit }, params) {
    if (!params.is_hide) startLoader();
    commit("apiCallback", true);
    await axios
      .get("/manual-quote/detail", { params: params })
      .then((res) => {
        if (!params.is_hide) hideLoader();
        if (res.data.status == true) {
          commit("storeQuoteDetail", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        if (!params.is_hide) hideLoader();
        alert(err);
        commit("apiCallback", false);
      });
  },

  // Pause Quote
  async pauseQuote({ commit }, params) {
    startLoader();
    commit("apiCallback", true);
    await axios
      .post("/manual-quote/status/in-progress", params)
      .then((res) => {
        hideLoader();
        if (res.data.status == true) {
          commit("storeQuoteDetail", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader();
        alert(err);
        commit("apiCallback", false);
      });
  },

  // Complete Quote
  async completeQuote({ commit }, params) {
    startLoader();
    commit("apiCallback", true);
    await axios
      .post("/manual-quote/status/complete", params)
      .then((res) => {
        hideLoader();
        if (res.data.status == true) {
          commit("storeQuoteDetail", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader();
        alert(err);
        commit("apiCallback", false);
      });
  },

  // Get Quote Summary
  async getQuoteSummary({ commit }, params) {
    startLoader();
    commit("apiCallback", true);
    await axios
      .post("/manual-quote/partner/summary", params)
      .then((res) => {
        hideLoader();
        if (res.data.status == true) {
          commit("storeQuoteSummary", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader();
        alert(err);
        commit("apiCallback", false);
      });
  },

  //pro/manual-quote/partner/update
  async postQuoteSummary({ commit }, params) {
    startLoader();
    commit("apiCallback", true);
    await axios
      .post("/manual-quote/partner/update", params)
      .then((res) => {
        hideLoader();
        if (res.data.status == true) {
          commit("updateQuoteSummary", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader();
        alert(err);
        commit("apiCallback", false);
      });
  },

  //pro/manual-quote/get-notes

  async getNotes({ commit }, params) {
    startLoader();
    commit("apiCallback", true);
    await axios
      .get("/manual-quote/get-notes", { params: params })
      .then((res) => {
        hideLoader();
        if (res.data.status == true) {
          commit("notesData", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader();
        alert(err);
        commit("apiCallback", false);
      });
  },

  //pro/manual-quote/status/canceled
  async postCancelQuote({ commit }, params) {
    startLoader();
    commit("apiCallback", true);
    await axios
      .post("/manual-quote/status/canceled", params)
      .then((res) => {
        hideLoader();
        if (res.data.status == true) {
          commit("storeQuoteDetail", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader();
        alert(err);
        commit("apiCallback", false);
      });
  },

  updateQuoteItems(context, quote) {
    context.commit("storeQuoteDetail", quote);
  },

  clearState(context) {
    context.commit(" storeQuoteSummary", null);
  },

  setCommponent({ commit }, data) {
    commit("updateComponentState", data);
  },

  async appointmentCancel({ commit }, params) {
    commit("apiCallback", true);
    await axios
      .put("/appointments/cancel", params)
      .then((res) => {
        if (res.data.status == true) {
          commit("saveApptointmentCancel", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        alert(err);
        commit("apiCallback", false);
      });
  },

  async checkoutAcknowledge({ commit }, params) {
    commit("apiCallback", true);
    await axios
      .post("/manual-quote/checkout/acknowledgment", params)
      .then((res) => {
        if (res.data.status == true) {
          commit("checkoutResponse", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        alert(err);
        commit("apiCallback", false);
      });
  },

  async getQRCode({ commit }, params) {
    startLoader();

    commit("apiCallback", true);

    await axios
      .get("/qrCode/job-detail", { params: params })
      .then((res) => {
        hideLoader();
        if (res.data.status == true) {
          console.log("updated rewpoe", res.data.body);
          commit("qrResponse", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        alert(err);
        hideLoader();
        commit("apiCallback", false);
      });
  },

  async postMarkAsComplete({ commit }, params) {
    startLoader();
    commit("apiCallback", true);
    await axios
      .post("/bookings/status/mark-as-complete", params)
      .then((res) => {
        hideLoader();
        if (res.data.status == true) {
          commit("savePostMarkAsComplete", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader();
        alert(err);
        commit("apiCallback", false);
      });
  },

  async startQuote({ commit }, params) {
    startLoader();
    commit("apiCallback", true);
    await axios
      .post("/manual-quote/status/start", params)
      .then((res) => {
        hideLoader();
        if (res.data.status == true) {
          commit("storeQuoteDetail", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader();
        alert(err);
        commit("apiCallback", false);
      });
  },

  async getReasons({ commit }, params) {
    startLoader();
    commit("apiCallback", true);
    await axios
      .get("/manual-quote/partner/cancel/reason", { params: params })
      .then((res) => {
        hideLoader();
        if (res.data.status == true) {
          commit("cancelReason", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader();
        alert(err);
        commit("apiCallback", false);
      });
  },

  async sendQuoteToEmail({ commit }, params) {
    startLoader();
    commit("apiCallback", true);
    await axios
      .post("/manual-quote/send", params)
      .then((res) => {
        hideLoader();
        if (res.data.status == true) {
          commit("emailQuoteResponse", res.data);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        alert(err);
        commit("apiCallback", false);
      });
  },

  async uploadQuoteImage({ commit }, params) {
    // console.log("----" , params.get('index'))
    //    var idx =   params.get('index');
    commit("apiCallback", true);
    await axios
      .post("/manual-quote/document/upload", params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status == true) {
          commit("quoteImageUploaded", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        alert(err);
        commit("apiCallback", false);
      });
  },

  async getQuoteImages({ commit }, params) {
    startLoader();
    commit("apiCallback", true);
    await axios
      .get("/manual-quote/document/list", { params: params })
      .then((res) => {
        hideLoader();
        if (res.data.status == true) {
          commit("quoteImages", res.data.body);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader();
        alert(err);
        commit("apiCallback", false);
      });
  },

  async deleteQuoteImages({ commit }, params) {
    startLoader();
    commit("apiCallback", true);
    await axios
      .delete("manual-quote/document/remove", { params })
      .then((res) => {
        hideLoader();
        if (res.data.status == true) {
          commit("deleteQuoteImage", true);
        } else {
          toast.error(res.data.message);
        }
        commit("apiCallback", false);
      })
      .catch((err) => {
        hideLoader();
        alert(err);
        commit("apiCallback", false);
      });
  },

  updateDeletedQuoteImages(context) {
    context.commit("deleteQuoteImage", false);
  },
};

// mutations
const mutations = {

  saveresubmitManualQuote(state, data) {
    state.resubmitQuote = data;
  },
  
  saveWaiveTradeCallFee(state, data) {
    state.waiveTradeCallFee = data;
  },

  saveComponentList(state, data) {
    state.componentList = data;
  },

  removeBookingDocument(state, data) {
    state.removeBookingDocs = data;
  },

  saveDeleteNotes(state, data) {
    state.deleteNoteResponse = data;
  },

  saveAddNotes(state, data) {
    state.addNoteResponse = data;
  },

  saveBookingDocs(state, data) {
    state.bookingDocs = data;
  },

  bookingConvert(state, data) {
    state.bookingConverted = data;
  },

  saveServices(state, data) {
    state.services = data;
  },

  saveBootmeUpData(state, data) {
    state.bootMeUpData = data;
  },

  saveUnitsInfo(state, data) {
    state.unitsList = data;
  },

  saveInitialChecks(state, data) {
    state.initialChecksList = data;
  },

  saveConsumerMembers(state, data) {
    state.consumerMembers = data;
  },

  saveEquipmentChecks(state, data) {
    state.equipmentChecksList = data;
  },

  saveBookingServices(state, data) {
    state.bookingServices = data;
  },

  saveBookingParts(state, data) {
    state.bookingParts = data;
  },

  saveBookingDiagnosisOptions(state, data) {
    state.bookingDiagnosisOptions = data;
  },

  saveDiagnosisOptions(state, data) {
    state.getDiagnosisOptions = data;
  },

  saveAllDiagnosisOptions(state, data) {
    state.allDiagnosisOptions = data;
  },

  savePostMarkAsComplete(state, data) {
    state.markAsCompleteResponse = data;
  },

  savePreChecksDetails(state, data) {
    state.preChecksDetail = data;
  },

  saveSpecialParts(state, data) {
    state.specialParts = data;
  },

  saveSummaryDetail(state, data) {
    state.summaryDetail = data;
  },

  savePreChecksResponse(state, data) {
    state.preChecksStore = data;
  },

  savePreConfig(state, data) {
    state.preConfig = data;
  },

  saveConfig(state, data) {
    state.config = data;
  },

  postUnitDetailResponse(state, data) {
    state.postUnitResponse = data;
  },

  saveBindUnit(state, data) {
    state.bindUnitResponse = data;
  },

  saveCollectData(state, data) {
    state.collectDataResponse = data;
  },

  saveUpdateCollectData(state, data) {
    state.updateCollectDataResponse = data;
  },

  saveUpdateJobStatus(state, data) {
    state.updateJobStatusResponse = data;
  },

  saveResumeJob(state, data) {
    state.resumeJobResponse = data;
  },

  saveEndJob(state, data) {
    state.endJobResponse = data;
  },

  saveResubmitQuote(state, data) {
    state.resubmitQuoteResponse = data;
  },

  saveBookingResponse(state, data) {
    state.bookingResponse = data;
  },

  storeChecksResponse(state, data) {
    state.storeChecksResponse = data;
  },

  saveBookingNotes(state, data) {
    state.bookingNotes = data;
  },

  saveMarkAsCompleteObject(state, data) {
    state.markAsCompleteObject = data;
  },

  savepartsDetails(state, data) {
    state.partsDetails = data;
  },

  saveJobDetails(state, data) {
    state.jobDetail = data;
  },

  saveBookingDiagnosisItems(state, data) {
    state.bookingDiagnosisItems = data;
  },

  saveDiagnosisSubmit(state, data) {
    state.diagnosisSubmitResponse = data;
  },

  saveDiagnosisUpdate(state, data) {
    state.diagnosisUpdateResponse = data;
  },

  deleteQuoteImage(state, deleteQuoteImage) {
    state.deleteQuoteImage = deleteQuoteImage;
  },

  quoteImages(state, quoteImages) {
    state.quoteImages = quoteImages;
  },

  saveApptointmentCancel(state, cancelAppointment) {
    state.cancelAppointment = cancelAppointment;
  },

  saveEquipmentInfoStore(state, saveEquipmentInfoStore) {
    state.saveEquipmentInfoStore = saveEquipmentInfoStore;
  },

  saveHvacEquipmentInfoStore(state, saveHvacEquipmentInfoStore) {
    state.saveHvacEquipmentInfoStore = saveHvacEquipmentInfoStore;
  },

  setEquipmentInfoStore(state, setEquipmentInfoStore) {
    state.setEquipmentInfoStore = setEquipmentInfoStore;
  },

  saveEquipmentInfo(state, saveEquipmentInfo) {
    state.saveEquipmentInfo = saveEquipmentInfo;
  },

  saveHvacEquipmentInfo(state, saveHvacEquipmentInfo) {
    state.saveHvacEquipmentInfo = saveHvacEquipmentInfo;
  },

  saveHvacConfig(state, saveHvacConfig) {
    state.saveHvacConfig = saveHvacConfig;
  },

  setEquipmentInfo(state, setEquipmentInfo) {
    state.setEquipmentInfo = setEquipmentInfo;
  },

  saveCompanyInfo(state, saveCompanyInfo) {
    state.saveCompanyInfo = saveCompanyInfo;
  },

  setCompanyInfo(state, setCompanyInfo) {
    state.setCompanyInfo = setCompanyInfo;
  },

  saveApptointmentCreatelObject(state, saveApptointmentCreatelObject) {
    state.saveApptointmentCreatelObject = saveApptointmentCreatelObject;
  },

  saveApptointmentActionArrived(state, saveApptointmentActionArrived) {
    state.saveApptointmentActionArrived = saveApptointmentActionArrived;
  },

  saveApptointmentActionEnroute(state, saveApptointmentActionEnroute) {
    state.saveApptointmentActionEnroute = saveApptointmentActionEnroute;
  },

  setAppointmentAction(state, appointmentAction) {
    state.appointmentAction = appointmentAction;
  },

  setAppointmentDetails(state, getAppointmentDetails) {
    state.getAppointmentDetails = getAppointmentDetails;
  },

  saveApptDetailObject: (state, appt) => {
    state.apptDetail = appt;
  },

  setSilentLogin(state, isSilentLogin) {
    state.isSilentLogin = isSilentLogin;
  },

  setSSOLogin(state, isSSOLogin) {
    state.isSSOLogin = isSSOLogin;
  },

  saveUserObject: (state, user) => {
    state.user = user;
  },

  saveDashboardData: (state, data) => {
    state.dashboardData = data;
  },

  apiCallback: (state, apiCallback) => {
    state.apiCallback = apiCallback;
  },

  notesData: (state, notesData) => {
    state.notesData = notesData;
  },

  savePartCategories: (state, partCategories) => {
    state.partCategories = partCategories;
  },

  savePartList: (state, partList) => {
    state.partList = partList;
  },

  storeQuoteItems: (state, storeQuoteItems) => {
    state.storeQuoteItems = storeQuoteItems;
  },

  storeQuoteDetail: (state, quoteDetail) => {
    state.quoteDetail = quoteDetail;
  },

  cancelReason: (state, cancelReasons) => {
    state.cancelReasons = cancelReasons;
  },

  storeQuoteSummary: (state, quoteSummary) => {
    state.quoteSummary = quoteSummary;
  },

  updateQuoteSummary: (state, updateQuoteSummary) => {
    state.updateQuoteSummary = updateQuoteSummary;
  },

  storeQuoteNotes: (state, qouteNotes) => {
    state.qouteNotes = qouteNotes;
  },

  updateComponentState: (state, componentState) => {
    state.componentState = componentState;
  },

  checkoutResponse: (state, checkoutResponse) => {
    state.checkoutResponse = checkoutResponse;
  },

  qrResponse: (state, qrResponse) => {
    state.qrResponse = qrResponse;
  },

  emailQuoteResponse: (state, quoteEmailResponse) => {
    state.quoteEmailResponse = quoteEmailResponse;
  },

  quoteImageUploaded: (state, quoteImageUploaded) => {
    state.quoteImageUploaded = quoteImageUploaded;
  },

  saveBookingImage: (state, data) => {
    state.bookingImageUploaded = data;
  },
  saveInProgressJobs: (state, data) => {
    state.inProgressJobs = data;
  },

  saveQRData: (state, data) => {
    state.QRData = data;
  },

  saveCompletedJobs: (state, data) => {
    state.completedJobs = data;
  },
};

function startLoader() {
  globals.$loader.hide();
  globals.$loader.show();
  // loader.hide()
  // loader.show({
  //   canCancel: false, // default false
  //   color: '#000000',
  //   loader: 'spinner',
  //   zIndex: 10000,
  // });
}

function hideLoader() {
  globals.$loader.hide();
}

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
