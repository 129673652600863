<template>
  <div>
    <div class="modalCntnt pt-3">
      <h4 class="blueText fw-bold text-center mb-3">
        Complete Booking ID # {{ booking.id }}
      </h4>
      <!-- <h6 class="orangeText text-center mb-2">Consumer Declined Quote</h6> -->
      <p class="blackText text-center mb-3">
        Are there any evaluation or other charges due?
      </p>

      <div class="cancelBookingOption">
        <div
          v-if="markAsCompleteObject.is_allied == 1"
          class="col-lg-12 col-md-12 col-sm-12 col-12"
        >
          <div
            class="form-group p-2 mb-2 rounded border d-flex justify-content-between align-items-center"
          >
            <div class="radioCheck d-flex">
              <div class="form__radio-group me-3">
                <input
                  id="customCharges"
                  type="radio"
                  class="form__radio-input"
                  name="operational"
                  checked
                />
                <label for="customCharges" class="form__radio-label">
                  <span class="form__radio-button"></span>
                  <span class="form__radio-label-text">TradeCall Fee</span>
                </label>
              </div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="me-2 fw-bold blueText">$</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter amount"
                v-model="diagnosisFee"
                @paste="disablePaste($event)"
                @keypress="isNumber($event)"
                @input="isNumber($event)"
              />
            </div>
          </div>
        </div>

        <div
          v-else-if="markAsCompleteObject.is_depp == 1"
          class="col-lg-12 col-md-12 col-sm-12 col-12"
        >
          <div
            class="form-group p-2 mb-2 rounded border d-flex justify-content-between align-items-center"
          >
            <div class="radioCheck d-flex">
              <div class="form__radio-group me-3">
                <input
                  id="eveluationCheck"
                  type="radio"
                  class="form__radio-input"
                  name="operational"
                  checked
                />
                <label for="eveluationCheck" class="form__radio-label">
                  <span class="form__radio-button"></span>
                  <span class="form__radio-label-text">Evaluation Charges</span>
                </label>
              </div>
            </div>
            <h5 class="mb-0 fw-bold blueText">
              {{ markAsCompleteObject.diagnosis_fee_text }}
            </h5>
          </div>
        </div>

        <div v-else class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div
            class="form-group p-2 mb-2 rounded border d-flex justify-content-between align-items-center"
            :class="{ disable: radioCheck != 0 }"
          >
            <div class="radioCheck d-flex">
              <div class="form__radio-group me-3">
                <input
                  id="normalEveluationCheck"
                  type="radio"
                  class="form__radio-input"
                  name="normalDeclined"
                  v-model="radioCheck"
                  value="0"
                />
                <label for="normalEveluationCheck" class="form__radio-label">
                  <span class="form__radio-button"></span>
                  <span class="form__radio-label-text">Evaluation charges</span>
                </label>
              </div>
            </div>
            <h5 class="mb-0 fw-bold blueText">
              {{ markAsCompleteObject.diagnosis_fee_text }}
            </h5>
          </div>
          <div
            class="form-group p-2 mb-2 rounded border d-flex justify-content-between align-items-center"
            :class="{ disable: radioCheck != 1 }"
          >
            <div class="radioCheck d-flex">
              <div class="form__radio-group me-3">
                <input
                  id="normalCustomCharges"
                  type="radio"
                  class="form__radio-input"
                  name="normalDeclined"
                  v-model="radioCheck"
                  value="1"
                />
                <label for="normalCustomCharges" class="form__radio-label">
                  <span class="form__radio-button"></span>
                  <span class="form__radio-label-text">Custom charges</span>
                </label>
              </div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="me-2 fw-bold blueText">$</label>
              <input
                type="text"
                class="form-control text-end"
                placeholder="Enter amount"
                :disabled="radioCheck != 1"
                v-model="diagnosisFee"
                @paste="disablePaste($event)"
                @keypress="isNumber($event)"
                @input="isNumber($event)"
              />
            </div>
          </div>
          <div
            class="form-group p-2 mb-2 rounded border"
            :class="{ disable: radioCheck != 2 }"
          >
            <div class="radioCheck d-flex">
              <div class="form__radio-group me-3">
                <input
                  id="nothing"
                  type="radio"
                  class="form__radio-input"
                  name="normalDeclined"
                  v-model="radioCheck"
                  value="2"
                />
                <label for="nothing" class="form__radio-label">
                  <span class="form__radio-button"></span>
                  <span class="form__radio-label-text">Nothing</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          markAsCompleteObject.reasons &&
          markAsCompleteObject.reasons.length > 0
        "
        class="addReasons mt-3"
      >
        <div class="form-group mb-3">
          <label class="mb-1">Select Reason</label>

          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span v-if="selectedReason != null">
                {{ selectedReason.reason }}</span
              >
              <span v-else>Select Reason</span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li
                @click="selectedReasonMethod(item)"
                v-for="(item, idx) in markAsCompleteObject != null &&
                markAsCompleteObject.reasons"
                :key="idx"
              >
                <a class="dropdown-item" href="#">{{ item.reason }}</a>
              </li>
            </ul>
          </div>

          <textarea
            v-if="selectedReason != null && selectedReason.reason == 'Other'"
            id="addReasonsTextarea"
            rows="3"
            class="form-control mt-3"
            placeholder="Enter reason here."
            v-model="otherReason"
          ></textarea>
        </div>
      </div>

      <p class="text-center mb-0 blueText fw-bold">
        <small>
          {{ markAsCompleteObject.note_description }}
        </small>
      </p>

      <div class="bottomButtons d-flex justify-content-center my-3">
        <button
          class="modalBtn buttonPrimary d-flex align-items-center me-2 text-uppercase"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          Cancel
        </button>
        <button
          class="modalBtn buttonSecondary d-flex align-items-center text-uppercase"
          @click="proceedBtn()"
        >
          Proceed
        </button>
      </div>
      <p class="text-center mb-0 blueText fw-bold">
        * Prices are exclusive of tax
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  props: ["booking", "markAsCompleteObject"],
  data() {
    return {
      diagnosisFee: "0",
      radioCheck: 0,
      selectedReason: null,
      otherReason: "",
    };
  },
  computed: {
    ...mapGetters(["markAsCompleteResponse"]),
  },

  watch: {
    markAsCompleteObject: function () {
      this.diagnosisFee = this.markAsCompleteObject.diagnosis_fee;
      this.selectedReason = this.markAsCompleteObject.reasons[0] ?? null;
    },

    markAsCompleteResponse: function () {
      this.$emit("event", "close");
    },
  },

  components: {},
  mounted() {},

  methods: {
    ...mapActions(["postMarkAsComplete"]),

    selectedReasonMethod(reason) {
      this.selectedReason = reason;
    },

    disablePaste(event) {
      event.preventDefault();
    },

    isNumber(evt) {
      //validate
      const inputElement = evt.target;
      let value = inputElement.value;
      if (evt.type === 'input') {
          value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
          const [integer, decimal] = value.split('.');
          if (integer.length > 4) value = integer.slice(0, 4);
          if (decimal) {
            if (integer === "") {
              value = "";
            } else {
              value = `${integer}.${decimal.slice(0, 2)}`;
            }
          }
          if (value.startsWith('.') || value === '') {
            value = '';
          }
          inputElement.value = value;
          inputElement.dispatchEvent(new Event('input', { bubbles: true }));
        } else if (evt.type === 'keypress') {
        const charCode = evt.which || evt.keyCode;
        if (charCode === 8 || charCode === 46 || charCode === 9 || (charCode >= 48 && charCode <= 57) ) {
          if (charCode === 46 && value.indexOf('.') !== -1) {
            evt.preventDefault();
            return false;
          }
          return true;
        }
        evt.preventDefault();
        return false;
      }
      // Remove invalid characters and multiple decimal points
      value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      const [integer, decimal] = value.split('.');
      // Restrict length
      if (integer.length > 4) value = integer.slice(0, 4);
      if (decimal) value = value.split('.')[0] + '.' + decimal.slice(0, 2);
      inputElement.value = value;
    },

    proceedBtn() {
      if (this.isValid()) {
        this.$dialog.show({
          title: "Alert",
          text: "Are you sure you want to decline this quote?",
          onConfirm: () => {
            this.postCall();
          },
        });
      }
    },

    postCall() {
      let storeObj = {
        booking_id: this.booking.id,
        
        tax_id: this.markAsCompleteObject.tax_id,
      };

      if (this.selectedReason != null){
        storeObj["reason"] = this.selectedReason.id
        if (this.selectedReason.reason == "Other") {
        storeObj["reason_other"] = this.otherReason;
        }
      }

      if (this.markAsCompleteObject.is_allied == 1) {
        storeObj["diagnosis_fee"] = this.diagnosisFee + "";
        storeObj["diagnosis_type"] = "diagnosis_fee";
      } else if (this.markAsCompleteObject.is_depp == 1) {
        storeObj["diagnosis_fee"] =
          this.markAsCompleteObject.diagnosis_fee + "";
        storeObj["diagnosis_type"] = "diagnosis_fee";
      } else {
        if (this.radioCheck == 0) {
          storeObj["diagnosis_fee"] =
            this.markAsCompleteObject.diagnosis_fee + "";
          storeObj["diagnosis_type"] = "diagnosis_fee";
        } else if (this.radioCheck == 1) {
          storeObj["diagnosis_fee"] = this.diagnosisFee + "";
          storeObj["diagnosis_type"] = "custom";
        } else {
          storeObj["diagnosis_fee"] =
            this.markAsCompleteObject.diagnosis_fee + "";
          storeObj["diagnosis_type"] = "nothing";
        }
      }

      this.postMarkAsComplete(storeObj);
    },

    isValid() {
      let res = true;
      if (this.markAsCompleteObject.reasons.length > 0 && this.selectedReason == null) {
        toast.error("Please select reason.");
        res = false;
      } else if (
        this.selectedReason != null &&
        this.selectedReason.reason == "Other" &&
        this.otherReason == ""
      ) {
        toast.error("Reason field is required.");
        res = false;
      } else if (this.markAsCompleteObject.is_allied == 1) {
        console.log(this.diagnosisFee);
        if (this.diagnosisFee + "" == "") {
          toast.error("Amount field is required.");
          res = false;
        }
      } else if (this.markAsCompleteObject.is_depp == 0) {
        if (this.radioCheck == 1) {
          if (this.diagnosisFee + "" == "") {
            toast.error("Amount field is required.");
            res = false;
          }
        }
      }
      return res;
    },
  },
};
</script>

<style>
.disable {
  opacity: 0.4 !important;
}
.dropdown-toggle,
.dropdown-item {
  white-space: normal !important;
}
#dropdownMenuButton1 {
  text-align: left;
  background: transparent;
  color: #000;
  padding: 0.5rem 2rem 0.5rem 0.5rem !important;
  border: 1px solid #eee;
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 16px !important;
  border-radius: 5px !important;
}
#dropdownMenuButton1:after {
  position: absolute;
  top: 50%;
  right: 10px;
  font-size: 20px;
  color: #fe5000;
}
</style>
